import { Component, HostListener, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location, DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Meta, Title } from '@angular/platform-browser';
import { DESCIPTION_BASE, TITLE } from './constant/app-constant'
import { Renderer2 } from '@angular/core';
import { FRONT_URL } from "./constant/app-constant";
import { PopUpComponent } from './util/pop-up/pop-up.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showHeader = true;
  showHeaderMobile = false
  showMenuDiv = false
  showMenuDivAction = true
  selectHome = true
  // title = 'news-first';
  base_url = FRONT_URL
  routerPath = 'home';
  showFiller = false;
  value: number = 0;
  selectedLang = 'si'
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private location: Location, @Inject(DOCUMENT) private dom: any, private metaService: Meta
    , private renderer2: Renderer2, private title: Title, @Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private document: Document, public dialog: MatDialog) {
    // @ts-ignore
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.routerPath = event.url;
        if (this.routerPath === "/") {
        } else {
          this.test(this.routerPath)
        }

      }
    });
  }

  ngOnInit() {
    // const s = this.renderer2.createElement('script');
    // s.type = 'text/javascript';
    // s.src = 'https://tags.adstudio.cloud/newsfirst.js';
    // this.renderer2.appendChild(this.document.body, s);
  }

  // ngAfterContentInit(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.dialog.open(PopUpComponent);
  //   }
  // }




  ChangingValue(val: any) {
    this.selectedLang = val.value
    // localStorage.setItem("lang", val.value)

  }

  test(type?: string, drawer?: any) {


    // localStorage.setItem("title", TITLE)
    if (type != "/home") {
      this.selectHome = false
    } else {
      this.selectHome = true
    }
    // localStorage.setItem("titile",TITLE)\\
    // const x: any = localStorage.getItem("title")
    //

    // this.routerPath = `${type}`;
    // this.title.setTitle(TITLE)
    this.moveUp()
    // this.router.navigate([this.routerPath]);
    drawer?.toggle();
  }

  moveUp() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  clickme(value: string, drawer?: any) {

    drawer?.toggle();
    this.router.navigateByUrl('/search', { state: { value: value } });
    // this.router.navigate([`/search/`]);
  }


}
