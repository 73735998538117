
// export const MAIN_URL: string = "http://34.100.158.54:3000/";
// export const MAIN_URL: string = "https://sinhala.newsfirst.lk/api/";
export const MAIN_URL: string = "https://apisinhala.newsfirst.lk/";
// // export const MAIN_URL: string = "http://34.149.161.101/";
// export const MAIN_URL: string = "http://localhost:3000/";


// export const FRONT_URL: string = "http://34.100.158.54:4000";
export const FRONT_URL: string = "https://sinhala.newsfirst.lk";
// export const FRONT_URL: string = "http://localhost:4200/";


export const TITLE: string = "ශ්‍රී ලංකා ප්‍රවෘත්ති | සිරස ප්‍රවෘත්ති | සිංහල | ශ්‍රී ලංකාවේ ප්‍රවෘත්ති"
export const TITLE_BUSINESS: string = "ව්‍යාපාරික පුවත් | ශ්‍රී ලංකාව | මූල්‍ය පුවත් | උද්ධමනය | අයවැය"
export const TITLE_SPORT: string = "ක්‍රීඩා පුවත් | ශ්‍රී ලංකාව | ක්‍රිකට් | රග්බි | පාපන්දු | නවතම ප්‍රවෘත්ති"
export const TITLE_FEATURED: string = "Featured Archives | Sri Lanka News - Newsfirst"
export const TITLE_LOCAL: string = "දේශීය පුවත් | උණුසුම් පුවත් ශ්‍රී ලංකා | නවතම පුවත්"
export const TITLE_LATEST: string = "Sri Lanka Latest News Articles Sri Lanka News - Newsfirst"
export const TITLE_WORLD: string = "අන්තර්ජාතික පුවත් | වීදේශීය පුවත් | නිව්ස් ෆස්ට්"
export const TITLE_TIMELINE: string = " | Sri Lanka News - Newsfirst"


export const DESCIPTION_BASE = "ශ්‍රී ලංකා ප්‍රවෘත්ති: ලොව පුරා නවතම ප්‍රවෘත්ති, සජීව ප්‍රවෘත්ති විකාශය සහ නවතම ව්‍යාපාර , ක්‍රීඩා පුවත් ලබා ගැනීම සදහා අප වෙබි අඩවියට පිවිසෙන්න."
export const DESCIPTION_BASE_LOCAL = "දිවයින පුරා නවතම දේශීය ප්‍රවෘත්ති ලබා ගැනීම සදහා නිව්ස් ෆස්ට් වෙබ් අඩවිය වෙත යොමු වන්න."
export const DESCIPTION_BASE_SPORT = "ශ්‍රී ලංකා පුවත්: ක්‍රිකට් පුවත් සියලුම නවතම ආවරණය, සජීවී වාර්තා, විශ්ලේෂණය සහ අදහස් දැක්වීම් වලට පිවිසෙන්න."
export const DESCIPTION_BASE_WORLD = "වීදේශීය පුවත්: ශ්‍රී ලංකාවේ ප්‍රමුක ප්‍රවෘත්ති වාර්තාකරු වන නිව්ස් ෆස්ට් ප්‍රවෘත්ති අංශයෙන් ලොව වටා ඇති නවතම සහ උණුසුම් පුවත් ලබා ගන්න."
export const DESCIPTION_BASE_BUSINESS = "ශ්‍රී ලංකා පුවත්: අපගේ ව්‍යාපාරික පුවත් පිටුවෙන් නවතම මූල්‍ය, ව්‍යාපාරික හා ආර්ථික ප්‍රවෘත්ති ලබා ගන්න."
export const DESCIPTION_BASE_TIMELINE = "Get the latest breaking news and top stories from Sri Lanka, the latest political news, sports news, weather updates, exam results, business news, entertainment news, world news and much more from News 1st, Sri Lanka&#039;s leading news network."
